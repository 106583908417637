import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ErrorInOTForm } from './ErrorInOTForm/ErrorInOTForm';
import { OTCreateButtonsGroup } from './OTCreateButtonsGroup/OTCreateButtonsGroup'
import {justDate} from '../../../../helpers/helpers'

export const OTProductInfoInputsC = ({otForm, setOtForm, initialState}) => {

  const [error, setError] = useState({status:false, message:''});
  const {customers} = useSelector(state => state.client.ticket_info)
  const {pathname} = useLocation();

  const { repair_date, place, total_price, ot_slug } = otForm;
  
  const handleChange = (e) => {
    if(e.target.name && e.target.name === 'file') {
      return setOtForm({
        ...otForm,
        [e.target.name]: e.target.files[0]
      });
    }
    return setOtForm({
      ...otForm,
      [e.target.name]: e.target.value
    });
  }

  const aux = document.getElementById('repair_date_input')

  useEffect(() => {
    if(aux) {
      aux.value = justDate(new Date(), true)
    }
  },[aux])

  return (
    <>

      <small>Reparación estimada</small>
      <input 
        className='panel_ot_create_input end_date'
        name='repair_date'
        onChange={ handleChange }
        placeholder='Reparación estimada'
        type='date'
        value={repair_date}
        id="repair_date_input"
      />
      
      <input 
        disabled={pathname.includes('edit')}
        autoComplete='off'
        className='panel_ot_create_input'        
        name='ot_slug'
        onChange={ handleChange }
        placeholder='Nº de OT'
        type='text'
        value={ot_slug}
      />
      
      <div className='panel_ot_create_input_datalist'>
        <input 
          autoComplete='off'
          className='panel_ot_create_input'
          name='place'
          onChange={ handleChange }
          list='ot_retiro'
          placeholder='Ubicación para retiro'
          type='text'
          value={place}
        />
        <datalist
          id='ot_retiro'
        >
          { customers?.map(customer => (
            <option 
              key={customer.id} 
              value={customer.name}
            >
              {`${customer.name} - (${customer.username})`}
            </option>
          )) }
        </datalist>
      </div>

      <input 
        autoComplete='off'
        className='panel_ot_create_input'        
        name='total_price'
        onChange={ handleChange }
        placeholder='Total ARG$ (sólo números)'
        type='text'
        value={total_price}
      />

      {pathname.includes('edit') &&
        <>
          <small>Foto de detalles OT</small>
          <input  
            accept="image/*"
            className='panel_ot_create_input file'
            name='file'
            onChange={ handleChange }
            type='file'
          /> 
        </>
      }

      <OTCreateButtonsGroup
        initialState={ initialState }
        setOtForm={ setOtForm }
        otForm={ otForm }
        setError={ setError }
        error={ error }
      />

      <ErrorInOTForm 
        setError={ setError }
        error={ error }
      />
    </>
  )
}
