import Tippy from '@tippyjs/react'
import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { otGetInfo } from '../../../actions/ot/ot-get'
import { otUpdateStatus, otWatchmakerTakeOrRelease } from '../../../actions/ot/ot-update'
import { otStatusList } from '../../../helpers/ot_status'
import { isCS, isPDV, isWatchmaker } from '../../../helpers/permission'
import { OTSimple } from '../OTDetails/OTSimple'
import { OTCheckBudget } from '../OTDetails/OTCheckBudget'
import { OTWatchmakerDerive } from '../OTDetails/OTWatchmakerDerive'
import { updateRma } from '../../../actions/ot/ot-detail'

export const OTRowOptions = ({row,setOtUpdates,otUpdates, isVoucher = false}) => {

  const [simple, setSimple] = useState({status:false,info:null});
  const [checkBudget, setCheckBudget] = useState({status:false,info:null});
  const [deriveShow, setDeriveShow] = useState({status:false,info:null});

  const dispatch = useDispatch()
  const history = useHistory()
  const role = useSelector(state => state.auth.role.name)
  const { general, toDeliver, toConfirm, repaired, inTransit,generalPDV,toDeliverPDV } = otStatusList
  const { status, slug } = row

  const watchmakerToR = async() => {
      const fd = new FormData()
    if(row.status && row.status === 'En revision') {
      fd.append('action','release')
      fd.append('id',`${row.slug}`)
    } else{
      fd.append('action','take')
      fd.append('id',`${row.slug}`)
    }
    await dispatch(otWatchmakerTakeOrRelease(fd))
    return dispatch(otGetInfo('general'));
  }

  const readyToDeliver = async() => {
    setSimple({status:true,info:''})
  }
  const handleCheck = async(e) => {
    setCheckBudget({status:true,info:''})
  }
  const handleDerive = async(e) => {
    setDeriveShow({status:true,info:''})
  }
  const handleReceive = async() => {
      const fd = new FormData()
      fd.append('action','receiveCS')
      await dispatch(updateRma(row.slug, fd));  
      return dispatch(otGetInfo('in-transit'));
  }
  
  return (
    <div className='ot_row_options'>

      {/**ABRIR EN PESTAÑA NUEVA */}
      {isCS(role) && 
        <Tippy content='Abrir en pestaña nueva' placement='bottom'>
          <button 
            onClick={() => window.open(`/panel/ot/${ slug }/detail`) }
            className='ot_row_button lilly'
            >
              <i className="fas fa-external-link-alt"></i>
          </button>
        </Tippy>
      }

      {/**EDITAR OT */}
      {((isCS(role) && status === 'En Customer Service') /* || (isPDV(role) && status === 'En PDV') */) &&
        <Tippy content='Editar información de OT' placement='bottom'>
          <button 
            onClick={() => history.push(`/panel/ot/${ slug }/edit`) }
            className='ot_row_button blue3'
          >
            <i className="fas fa-edit"></i>
          </button>
        </Tippy>
      }
      
      {/**RECIBIR OT EN CS */}
      {(isCS(role) && inTransit.includes(status)) &&
        <Tippy content='Recibir en CS' placement='bottom'>
          <button 
            onClick={handleReceive}
            className='ot_row_button blue2'
            >
              <i className="fas fa-hand-holding"></i>
          </button>
        </Tippy>
      }

      {/**LISTO PARA ENTREGAR / CERRAR OT */}
      {((isCS(role) && toDeliver.includes(status)) || (isPDV(role) && toDeliverPDV.includes(status))) &&
        <Tippy content='Listo para entrega/cerrar OT' placement='bottom'>
          <button 
            className='ot_row_button green'
            onClick={readyToDeliver}
          >
            <i className="fas fa-check-circle"></i>
          </button>
        </Tippy>
      }

      {/**PRESUPUESTO A CONFIRMAR */}
      {(isCS(role) && toConfirm.includes(status)) &&
        <Tippy content='Presupuesto a confirmar' placement='bottom'>
          <button 
            onClick={handleCheck}
            className='ot_row_button yellow3'
            >
              <i className="fas fa-coins"></i>
          </button>
        </Tippy>
      }
      
      {/**RECHAZAR OT */}
      {(isCS(role) && status === 'Reparado') &&
        <Tippy content='Rechazar reparación' placement='bottom'>
          <button 
            onClick={handleDerive}
            className='ot_row_button red1'
            >
              <i className="fas fa-window-close"></i>
          </button>
        </Tippy>
      }

      {/**TOMAR/SOLTAR OT */}
      {(isWatchmaker(role)) &&
        <Tippy content='Tomar/soltar OT' placement='bottom'>
          <button 
            className={`ot_row_button ${(row.status && row.status === 'En revision') ? 'blue2' : 'yellow4'}`}
            onClick={watchmakerToR}
          >
            {(row.status && row.status === 'En revision') ? <i className="fas fa-hand-rock"></i> : <i className="fas fa-hand-paper"></i> }
          </button>
        </Tippy>
      }

      {/**VER ACTUALIZACIONES OT */}
      {(isPDV(role)) &&
        <Tippy content='Ver actualizaciones OT' placement='bottom'>
          <button 
            className={`ot_row_button yellow3`}
            onClick={() => setOtUpdates({status:true,info:{slug}})}
          >
            <i className="fas fa-search"></i>
          </button>
        </Tippy>
      }
        {
          ((isCS(role) && 
            (row.status === 'Presupuesto aceptado' 
            || row.status === 'En Customer Service' 
            || row.status === 'Reparacion gratuita')) || isWatchmaker(role))  &&
              <Tippy content='Derivar a relojero' placement='bottom'>
                <button 
                  className={`ot_row_button yellow3`}
                  onClick={handleDerive}
                >
                  <i className="fas fa-tools"></i>
                </button>
              </Tippy>
        }

      {/**DEVOLVER OT */}
      {/* {(isPDV(role)) &&
        <Tippy content='Devolver OT' placement='bottom'>
          <button 
            className='ot_row_button red2'
          >
            <i className="fas fa-undo-alt"></i>
          </button>
        </Tippy>
      } */}


        {(row.status === 'Para entrega cliente en CS' 
      || row.status === 'Para entrega cliente en PDV')&&
          <OTSimple
            slug={slug}
            status={{name : row.status}}
            show={simple}
            onHide={() => setSimple({status:false,info:null})}
          />
        }
        { row.status === 'Presupuestado' &&
        
          <OTCheckBudget
            slug={slug}
            show={checkBudget}
            onHide={() => setCheckBudget({status:false,info:null})}
          />
        }
        {
          (( isCS(role) && (row.status === 'Reparado' 
            || row.status === 'Presupuesto aceptado' 
            || row.status === 'En Customer Service' 
            || row.status === 'Reparacion gratuita')) || isWatchmaker(role) )&&
          <OTWatchmakerDerive
            slug={slug}
            status={{name : row.status}}
            show={deriveShow}
            onHide={() => setDeriveShow({status:false,info:null})}
          />
        }
    </div>
  )
}
