import React from 'react'
import { formatDateWithTime } from '../../../helpers/helpers';

export const OTDetailInfo = ({ot}) => {
    //console.log(`detail`, ot)
    const {detail, rma, rmaObject, deliver} = ot;        
    //console.log(Boolean(rma.observations))
  return ( 
    <div className='seccion-detalle-1'>
        <h5>Datos de contacto</h5>
        <div className='seccion-detalle-datos'>
            <div className='seccion-detalle-datos-info ot'>
                <div className='subtitle'>
                    <p>Cliente</p>
                </div>
                <div>
                    <p>Nombre: </p>
                    <span>{rma.client && rma.client.name} { rma.client && rma.client.lastname } </span>
                </div>
                <div>
                    <p>DNI: </p>
                    <span>{ rma.client && rma.client.dni }</span>
                </div>
                <div>
                    <p>Teléfono: </p>
                    <span>{ rma.client && rma.client.phone }</span>
                </div>
                <div>
                    <p>Correo: </p>
                    <span>{ rma.client && rma.client.email }</span>
                </div>
                <div>
                    <p>Correo secundario: </p>
                    <span>{ (rma.client && rma.client.optional_email) ? rma.client.optional_email : '-' }</span>
                </div>


                <div className='subtitle'>
                    <p>OT</p>
                </div>
                <div>
                    <p>Número: </p>
                    <span>{ rma.slug ? `#${rma.slug}` : `Ticket #${detail.slug ? detail.slug : ''}` }</span>
                </div>
                <div>
                    <p>Estado: </p>
                    <span>{ detail.status?.name }</span>
                </div>
                <div>
                    <p>Fecha de registro: </p>
                    <span>{ rma.created_at ? formatDateWithTime(rma.created_at) : '-'}</span>
                </div>
                <div>
                    <p>Marca: </p>
                    <span>{ rmaObject?.brand.name }</span>
                </div>
                <div>
                    <p>Modelo: </p>
                    <span>{ rmaObject?.model }</span>
                </div>
                <div>
                    <p>Tipo: </p>
                    <span>{ rmaObject?.brand.type  }</span>
                </div>
                <div>
                    <p>Garantía: </p>
                    <span>{ rma.due_date ? formatDateWithTime(rma.due_date) : 'Sin garantía' }</span>
                </div>
                <div>
                    <p>Presupuesto: </p>
                    <span>{rma.total_amount ? `$${rma.total_amount} ` : 'Sin presupuesto'}</span>
                </div>
                <div>
                    <p>Fecha estimada: </p>
                    <span>{ rma.fix_date ? formatDateWithTime(rma.fix_date) : '-'}</span>
                </div>
                <div>
                    <p>Lugar de retiro: </p>
                    <span>{ deliver[0]?.username }</span>
                </div>
                <div>
                    <p>Plan </p>
                    <span>{rma.warranty_state?.name}</span>
                </div>
                <div className='bigtext'>
                    <p>Prediagnóstico: </p>
                    <span>{`${rma.pre_diagnostic?.toLowerCase()} ${rma?.other_pre_diagnostic ? rma.other_pre_diagnostic.toLowerCase() : ''}` }</span>
                </div>
                {
                    Boolean(rma.observation) &&
                    <div className='bigtext'>
                        <p>Observaciones: </p>
                        <span>{ rma.observation }</span>
                        <div className='bigtext_observations'>
                            { rma?.observations.length > 0 
                            && rma.observations.map(obs => (
                                <span
                                    key={obs.id}
                                >
                                    <strong>{obs.type} : </strong>{obs.observation}
                                </span>
                            )) }
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}
