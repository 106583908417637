import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { otSearchClient } from '../../../../actions/ot/ot-create';

export const OTUserInfoInputs = ({otForm, setOtForm, userSearch, setUserSearch, initialState}) => {
  const {rma} = useSelector(state => state.client.ticket_info)
  const dispatch = useDispatch();
  const {pathname} = useLocation();

  const { dni, name, last_name, telephone, mail, mail2, has_warranty, warranty } = otForm;
  const {client_info} = useSelector(state => state.client)

  const handleChange = (e) => {
    if(e.target.name === 'has_warranty') {
      return setOtForm({
        ...otForm,
        [e.target.name]: !has_warranty
      });
    }
    return setOtForm({
      ...otForm,
      [e.target.name]: e.target.value
    });
  }

  const searchForUserInfo = () => {
    if(dni) {
      const fd = new FormData(); fd.append('dni',dni)
      dispatch(otSearchClient( fd, setUserSearch ))
    } 
  }
  
  useEffect(() => {
    if(client_info.name && !userSearch.status) {
      setOtForm({
        ...otForm, 
        name:client_info.name, 
        last_name:client_info.lastname, 
        telephone:client_info.phone, 
        mail:client_info.email, 
        mail2:client_info.optional_email ? client_info.optional_email : '',
      })
    } else {
      setOtForm(initialState)
    }
  },[ userSearch, client_info ]);

  useEffect(() => {
    if(rma && rma.warranty) {
      document.querySelector('#OTProductWarranty').checked = Boolean(rma.warranty)
    }
  },[rma])
  return (
    <>
      <div className='ot_search_input'>
        <input 
          autoComplete='off'
          className='panel_ot_create_input'
          name='dni'
          onChange={ handleChange }
          placeholder='DNI (sólo números)'
          type='text'
          value={dni}
        />
        { pathname.includes('panel/ot/create') && 
          <button
            type='button'
            className='ot_search_user_btn'
            onClick={ searchForUserInfo }
          >
            <i className="fas fa-search"></i>
          </button>
        }
      </div>
      <input 
        autoComplete='off' 
        className='panel_ot_create_input'
        name='name'
        onChange={ handleChange }
        placeholder='Nombre del cliente'
        type='text'
        value={name}
      />
      <input 
        autoComplete='off' 
        className='panel_ot_create_input'
        name='last_name'
        onChange={ handleChange }
        placeholder='Apellido del cliente'
        type='text'
        value={last_name}
      />
      <input 
        autoComplete='off' 
        className='panel_ot_create_input'
        name='telephone'
        onChange={ handleChange }
        placeholder='Teléfono (sólo números)'
        type='text'
        value={telephone}
      />
      <input 
        autoComplete='off' 
        className='panel_ot_create_input'
        name='mail'
        onChange={ handleChange }
        placeholder='Correo'
        type='email'
        value={mail}
      />
      <input 
        autoComplete='off' 
        className='panel_ot_create_input'
        name='mail2'
        onChange={ handleChange }
        placeholder='Correo secundario'
        type='email'
        value={mail2}
      />
      <div className='ot_warranty_date'>
        <label 
          htmlFor='OTProductWarranty'
          className='ot_has_warranty'
        >
          <input
            id='OTProductWarranty'
            name='has_warranty'
            onChange={ handleChange }
            type='checkbox'
            value={has_warranty}
          />
          Garantía
        </label>
        <input 
          className='panel_ot_create_input'
          disabled={!has_warranty}
          name='warranty'
          onChange={ handleChange }
          placeholder='Correo secundario'
          type='date'
          value={warranty}
        />
      </div>
    </>
  )
}
