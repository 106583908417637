import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { loadRma, updateRma } from '../../../actions/ot/ot-detail'
import { otGetInfo } from '../../../actions/ot/ot-get'

import { isCS, isPDV } from '../../../helpers/permission'
import { formatDateWithTime, justDate } from '../../../helpers/helpers'
import makeRequest from '../../../actions/makeRequest'
import { strGenerator } from '../../../lib/randomstring'

const request = { 
  method:'post', 
  url:'api/voucher/create', 
  data: {}, 
  storeType:'', 
  headers:{
    'Authorization':'Bearer ' + localStorage.getItem('token')
  } 
}

export const OTVoucher = (props) => {
  const initialValue = {
    code:'',
    date_from: new Date(),
    date_to: new Date(Date.now() + (1000 * 60 * 60 * 24 * 60)), // Dos meses
    description:'',
    active:false,
  }
  const [voucher, setVoucher] = useState(initialValue)
  const [res, setRes] = useState({body:'',response:''})
  const {
    code,
    date_from,
    date_to,
    description,
    active
  } = voucher

  const handleChange = e => {
    const {name,value,checked} = e.target
    if(name === 'active') {
      return setVoucher(v => ({
        ...v, 
        [name]:checked
      }))
    }
    return setVoucher(v => ({
      ...v,
      [name]:value
    }))
  }
  const handleSend = async(e) => {
    e.preventDefault()
    e.stopPropagation()

    // const aux = Object.entries(voucher)

    // const fd = new FormData() 
    // fd.append('rma_id',props.show?.info?.slug)
    // aux.map(item => 
    //   (item[0] === 'active' && item[1] === true) ? 
    //     fd.append('active',1) : 
    //     fd.append(`${item[0]}`,item[1]))
    if( !code ) return setError({status:true, message:'Genere un código único de voucher'})

    request.data = {
      ...voucher, 
      rma_id : props.show?.info?.slug,
      description: description.length ? description : `Descripción por defecto de voucher para OT ${props.show?.info?.slug}`,
      date_from:justDate(date_from,true),
      date_to:justDate(date_to,true),
    }
    const {message, response} = await dispatch(makeRequest(request))
    setRes({body:message, response})
    setVoucher(initialValue)
    // console.log(voucher)
    return false
  }
  const [error, setError] = useState({status:false, message:''})
  const {name:role} = useSelector(state => state.auth.role)
  const dispatch = useDispatch()
  const generateID = e => {
    e.preventDefault()
    e.stopPropagation()
    return setVoucher(v => ({
      ...v, 
      code: strGenerator()
    }))
  }
  return (
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='ot_watchmaker_repair_modal'
    >
      <Modal.Header closeButton className='ot_watchmaker_repair_modal_top'>
        <Modal.Title id="contained-modal-title-vcenter watchmaker_to_derive_title">
          <h6>Crear un nuevo voucher</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='ot_watchmaker_repair_modal_body m-0'>
        <div className='ot_watchmaker_input_to_derive'>
          {
            error.status 
            ? <small className='text-danger'>{error.message}</small>
            : <small>Si desea crear un nuevo voucher para este OT, ingrese la información necesaria y haga click en enviar</small>
          }
          {
            res.body?.length
            ? <p>{res.body}: <strong>{res.response}</strong></p>
            : <form className='voucher_form'>
            <div className="voucher_input_container ot_watchmaker_input_to_derive">
              <label htmlFor="voucher_input_1">Código del voucher</label>
              <input 
                type='text'
                placeholder='3XAMPL30FC0D3'
                name='code'
                value={code}
                id='voucher_input_1'
                onChange={handleChange}
              />
              <button 
                className="generate-id"
                onClick={generateID}
              >
                <i className="fas fa-plus-circle"></i>
              </button>
            </div>
            <div className="voucher_input_container ot_watchmaker_input_to_derive">
              <span>
                <label htmlFor="voucher_input_2">Fecha de inicio</label>
                <input 
                  type='date'
                  name='date_from'
                  id='voucher_input_2'
                  value={date_from}
                  onChange={handleChange}
                />
              </span>
              <span>
                <label htmlFor="voucher_input_3">Fecha de fin</label>
                <input 
                  type='date'
                  name='date_to'
                  id='voucher_input_3'
                  value={date_to}
                  onChange={handleChange}
                />
              </span>
            </div>
            <div className="voucher_input_container ot_watchmaker_input_to_derive">
              <label htmlFor="voucher_input_4">Descripción del voucher</label>
              <input 
                type='text'
                placeholder='Descripción'
                name='description'
                id='voucher_input_4'
                value={description}
                onChange={handleChange}
              />
            </div>
            <div class="voucher_input_container ot_watchmaker_input_to_derive checkbox">
              <input 
                type='checkbox'
                name='active'
                id='voucher_input_5'
                value={active}
                onChange={handleChange}
              />
              <label htmlFor="voucher_input_5">Estado activo</label>
            </div>
          </form>
          }
        </div>
      </Modal.Body>
      <Modal.Footer className='ot_watchmaker_repair_modal_bottom ot_watchmaker_derive_buttons'>
        <Button
          variant="info" 
          onClick={handleSend}
        >
          <i className="fas fa-clipboard-check"></i>Enviar
        </Button>

        <Button 
          variant="danger" 
          onClick={props.onHide}
        >
          Volver
        </Button>

      </Modal.Footer>
    </Modal>
  )
}
