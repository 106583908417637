import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { OTCreateTicket } from '../../components/OT/OTCreateTicket/OTCreateTicket'
import { OTEdit } from '../../components/OT/OTCreateTicket/OTEdit'
import { Viewer } from '../../components/OT/OTCreateTicket/OTExport/Viewer'
import { OTDetail } from '../../components/OT/OTDetails/OTDetail'
import { Perfil } from '../../components/Perfil'
import { isCS, isPDVorCS, isWatchmaker } from '../../helpers/permission'
import { PATHS } from '../../types/paths'

import {
	OtTicketsIndex,
	TicketsDetail,
	ClientTicketIndex
} from '../../pages'

const Routes = () => {
  const {name:role} = useSelector(state => state.auth.role)
	return (
		<div className='seccion-tabla panel_cliente'>
			<div className='seccion-tabla-interno'>
				<Switch>

					<Route //RUTA PARA CONFIGURACIONES DE PERFIL
						exact 
						path={PATHS.panelConfigProfile} 
						component={Perfil} 
					/>   

					{
						(role && isPDVorCS(role)) &&
						<Route //RUTA PARA CREAR OT
							exact 
							path={PATHS.otCreateTickets} 
							component={OTCreateTicket} 
						/>
					}
					
					{
						isCS(role) &&
						<Route //RUTA PARA EDITAR OT
							exact 
							path={PATHS.otEdit} 
							component={OTEdit} 
						/>
					}
					
					<Route //RUTA PARA OT INDEX
						exact
						path={PATHS.otTickets} 
						component={OtTicketsIndex} 
					/>

					{
						(isCS(role) || isWatchmaker(role)) &&
						<Route //RUTA PARA DETALLES DE OT
							path={PATHS.otDetails} 
							component={OTDetail} 
						/>
					}
					{/* <Route //RUTA PARA DETALLES DE OT
						path={PATHS.editRoom} 
						component={Viewer} 
					/> */}
					
					<Redirect to={PATHS.otGeneralTickets} />
				</Switch>
			</div>
		</div>
	)
}

export default Routes
