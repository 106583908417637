import React from 'react';
import Axios from "axios";
import { Redirect } from "react-router-dom";
import { TYPES } from "../types/types";
import { error, isLoading, isNotLoading } from "./ui";
import { deleteAllTickets } from './tickets';
import { deleteInfoHeader } from './header';
import {deleteUserInfo} from './user/user-profile'
import { cleanTheStorage } from '../helpers/clean_the_storage';

export const startLogin = ( value ) => {
    return (dispatch) => {
        dispatch(isLoading());
        Axios.post( 'api/login', value)
        .then( res => {
            localStorage.setItem( 'token', res.data.token );
            dispatch( login(res.data.user, res.data.role) );
            dispatch(isNotLoading());
        })
        .catch( err => {
            dispatch(error('El servidor no registra usuarios con los datos ingresados'));
            dispatch(isNotLoading());
            //console.log(err.response.data.message);<--Como obtener el mensaje del response desde la api
        } )
    }
};


export const login = ( user, role ) => ({
    type: TYPES.login,
    payload: {
        user: user,
        role: role
    }
});

export const startLogout = () => {
    return async(dispatch) => {
        
        try {
            await Axios.get('api/logout',{
                    headers:{
                        'Authorization':'Bearer ' + localStorage.getItem('token')
                    }
                }).then( res =>{
                    
                    if(res.data.status === 'success'){
                        localStorage.clear();
                        dispatch(logout());
                        return dispatch(cleanTheStorage(false));
                    } else {
                        return alert('No se pudo cerrar sesión correctamente');
                    }
                } ).catch( err => {
                    return alert('No se pudo cerrar sesión correctamente');;
                } )

        } catch (error) {
            return alert('No se ha podido cerrar sesión');
        }
    }
}

export const logout = () => ({
    type: TYPES.logout
})