import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap'
import { justDate, stringForDate } from '../../../helpers/helpers';
import { handleChange, submitFunction, submitValidation } from './events';
import { voucherState, errorState, modalState } from './states';
import ConfirmationModal from '../SimpleConfirmationModal';

const EditVoucherModal = ({ 
	onHide, voucherInfo, modalSize = 'lg', modalText, ...rest 
}) => {
  const [voucher, setVoucher] = useState(voucherState);
  const [error, setError] = useState(errorState);
  const [modal, setModal] = useState(modalState);

  const dispatch = useDispatch()

  const {
    description,
    rma_id,
    client_id,
    status,
    voucher_id
  } = voucherInfo

	const submit = async(e) => {
		e.preventDefault()

		const {status, message:valMessage} = submitValidation(voucher)
		if(status) {
			return setError({status,message: valMessage})
		}
    setError({ status: false, message: '' })
    return (setModal({
      ...modal,
      modalShow: true,
    }))
/* 
    const {message, response} = await fnToInvoke()
		return setMessage({active:false, body: message, response}) */
	}

  const submitSetup = { voucher, dispatch, setVoucher }
  const aux = {
      show: modal.modalShow,
      onHide:() => setModal({
        ...modal,
        modalShow: false,
      }),
      fnToInvoke: () => submitFunction(submitSetup),
      modalSize : 'md',
      backdrop : 'static',
			modalText: 'Para confirmar el envío de información para ACTUALIZAR el Voucher OT, por favor presione en -Enviar-'
  }

  
  useEffect(() => {
    if(rma_id){
      setVoucher({
        voucher_id,
        description,
        rma_id,
        client_id,
        active: status ? true : false,
        date_from: new Date(),
        date_to: new Date(Date.now() + (1000 * 60 * 60 * 24 * 60)) // Dos meses),
      })
      setError(errorState)
    }
  },[description, rma_id, client_id, status,voucher_id])

	return (
    <Modal
      {...rest}
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{border:'none'}}>
        <Modal.Title id="contained-modal-title-vcenter">
          Actualizar Voucher OT
          <small style={{display:'block', width:'100%'}} className={ 'panel_cliente_crear_ticket_aclaracion ' + (error.status && 'error_color')}>
              {
                  error.status ? error.message : 'Ingrese los datos correspondientes para actualizar un voucher OT'
              }
          </small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        paddingTop:'0',
      }}>

      <form className='ot_search_form' style={{
        paddingTop:'0',border:'none'
      }}>

          <div>
              <p className="panel_cliente_crear_ticket_nuevo"></p>
              <div className="panel_cliente_crear_ticket_inputs" style={{
                paddingTop:'0',
              }}>
                <small>DNI del cliente</small>
                <input 
                  readOnly
                  type='text'
                  placeholder='DNI de cliente'
                  name='client_id'
                  id='voucher_input_1'
                  value={voucher.client_id}
                  style={{color:'grey'}}
                />
                <small>OT para asignar el voucher</small>
                <input 
                  readOnly
                  type='text'
                  placeholder='# OT'
                  name='rma_id'
                  id='voucher_input_2'
                  value={voucher.rma_id}
                  style={{color:'grey'}}
                />
                <div className="voucher_input_container ot_watchmaker_input_to_derive dates">
                  <small>Rango de fechas</small>
                  <input 
                    type='date'
                    name='date_from'
                    id='voucher_input_3'
                    value={voucher.date_from}
                    onChange={e => handleChange(setVoucher,e)}
                  />

                  <input 
                    type='date'
                    name='date_to'
                    id='voucher_input_4'
                    value={voucher.date_to}
                    onChange={e => handleChange(setVoucher,e)}
                  />
                </div>

                <small>Descripción(opcional)</small>
                <input 
                  type='text'
                  placeholder='Descripción'
                  name='description'
                  id='voucher_input_5'
                  value={voucher.description}
                  onChange={e => handleChange(setVoucher,e)}
                />
                <div className="voucher_input_container ot_watchmaker_input_to_derive checkbox">
                  <input 
                    type='checkbox'
                    name='active'
                    checked={voucher.active}
                    id='edit_voucher_input_6'
                    onChange={e => handleChange(setVoucher,e)}
                  />
                  <label htmlFor="edit_voucher_input_6">Estado activo</label>
                </div>

              </div>
          </div>
          <br />

          <ConfirmationModal
            {...aux}
          />
      </form>
      </Modal.Body>
      <Modal.Footer style={{border:'none'}}>
          <div className='ot_button_group_1' style={{width:'100%'}}>
          
            <button
							onClick={submit}
              className='ot_create_button green'
            > 
              Actualizar Voucher OT
            </button>

            <button
              onClick={(e) => {
                e.preventDefault()
                // setVoucher(voucherState)
					      onHide()
              }}
              className='ot_create_button red1'
            > 
              Cerrar
            </button>
          </div>
      </Modal.Footer>
    </Modal>
	)
}

export default EditVoucherModal
