import React from 'react'

export const OTWatchmakerRepairListType = ({setToRepair,toRepair}) => {

  const handleChange = e => {
    setToRepair({
      ...toRepair,
      repairType:e.target.value
    })
  }

  return (
    <div 
      className='ot_watchmaker_repair_list_type'
    >
      <div>
        <input
          className='ot_watchmaker_repair_list_input'
          id='ot_watchmaker_repair_list_type_1'
          type='radio'
          name='cambio'
          value='cambio'
          onChange={handleChange}
        />
        <label htmlFor='ot_watchmaker_repair_list_type_1'>
          Cambio total
        </label>
      </div>
      <div>
        <input
          className='ot_watchmaker_repair_list_input'
          id='ot_watchmaker_repair_list_type_2'
          type='radio'
          name='cambio'
          value='pcan'
          onChange={handleChange}
        />
        <label htmlFor='ot_watchmaker_repair_list_type_2'>
          Plan canje
        </label>
      </div>
      <div>
        <input
          className='ot_watchmaker_repair_list_input'
          id='ot_watchmaker_repair_list_type_3'
          type='radio'
          name='cambio'
          value='rep'
          onChange={handleChange}
        />
        <label htmlFor='ot_watchmaker_repair_list_type_3'>
          Reparación
        </label>
      </div>
    </div>
  )
}
