import React, { useEffect,useState } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux';
import { otGetToAssignInfo } from '../../../../actions/ot/ot-get';
import { justDate } from '../../../../helpers/helpers';
import { isCS } from '../../../../helpers/permission';
import { Loader } from '../../../Loader/Loader';
import ConfirmationModal from '../../../../components_new/modals/SimpleConfirmationModal';
import { editModalState, modalState, PDFModalState } from './states';
import { submitFunction } from './events';
import Tippy from '@tippyjs/react';
import DownloadPDFModal from '../../../../components_new/modals/DownloadPDFModal';
import OTVoucher from '../../../../components_new/templates/pdf/A4/OTVoucher';
import EditVoucherModal from '../../../../components_new/modals/EditVoucherModal';

const OTVoucherDatatable = ({filterText}) => {
  let auxVouchers, filteredItems;

  const {name:role} = useSelector(state => state.auth.role)
  const { vouchers } = useSelector(state => state.ot);
  const { loading } = useSelector(state => state.ui)  
	const [editModal, setEditModal] = useState(editModalState);
	const [PDFModal, setPDFModal] = useState(PDFModalState);
	const [modal, setModal] = useState(modalState);
	const [voucher, setVoucher] = useState({});
	
  const dispatch = useDispatch()
  
  if ( vouchers.length > 0 ) {
      auxVouchers = vouchers.map( (voucher) => {
          return {
            voucher_id: voucher.id,
            rma_id: voucher.swatch_rma?.slug ? voucher.swatch_rma.slug : '-', 
            client_id: voucher.swatch_rma?.client?.dni ? voucher.swatch_rma.client?.dni : '-', 
            code: voucher.code || '-',
            description: voucher.description || '-',
            date_from: justDate(voucher.date_from),
            date_to: justDate(voucher.date_to),
            status: voucher.active,
            isUsed: voucher.used,
          }
      })

      filteredItems = auxVouchers.filter(item => (item.rma_id && (item.rma_id.toString()).includes(filterText))
          || (item.code && item.code.toLowerCase().includes(filterText.toLowerCase())) 
          || (item.date_from && item.date_from.includes(filterText)) 
          || (item.date_to && item.date_to.includes(filterText)) 
          || (item.status && item.status.toLowerCase().includes(filterText.toLowerCase())) 
          || (item.isUsed && item.isUsed.toLowerCase().includes(filterText.toLowerCase())) 
      );

      
  }

  const colsVouchers = [
    {   
        grow: 1,
        name:'#OT',
        selector:'rma_id',
        sortable: true
    },
    {   
        grow: 1,
        name:'Código',
        selector:'code',
        sortable: true
    },
    { 
        grow: 1, 
        name:'Fecha inicio',
        selector:'date_from',
        sortable: true
    },
    { 
        grow: 1, 
        name:'Fecha fin',
        selector:'date_to',
        sortable: true
    },
    { 
        grow: 1, 
        name:'Estado',
        selector:'status',
        sortable: true,
        cell: row => row.status ? 'Activo' : 'Inactivo'
    },
    { 
        grow: 1, 
        name:'Usado',
        selector:'isUsed',
        sortable: true,
        cell: row => row.isUsed ? 'Usado' : 'Sin Usar'
    },
    { 
        grow: 1, 
        name:'Opciones',
        selector:'options',
        cell: row => (
					<div className='ot_row_options'>
						<Tippy content={`Estado ${row.status ? 'Activo' : 'Inactivo'}`} placement='bottom'>
							<button 
								onClick={() => {
									setVoucher(row)
									setModal({
										...modal,
										modalShow: true,
									})
								}}
								className={`ot_row_button ${row.status ? 'green' : 'yellow1'}`}
								>
									<i className={`far fa${row.status ? '-check' : ''}-circle`}></i>
							</button>
						</Tippy>
						<Tippy content="Descargar PDF" placement='bottom'>
							<button 
								onClick={() => {
									setVoucher(row)
									setPDFModal({
										...modal,
										modalShow: true,
									})
								}}
								className='ot_row_button red1'
								>
									<i className="fas fa-file-pdf"></i>
							</button>
						</Tippy>
						<Tippy content="Actualizar voucher" placement='bottom'>
							<button 
								onClick={() => {
									setVoucher(row)
									setEditModal({
										...modal,
										modalShow: true,
									})
								}}
								className='ot_row_button blue3'
								>
                                    <i className="fas fa-edit"></i>
							</button>
						</Tippy>
					</div>
				)
    },
  ]


  const pagOpts = {
      rowsPerPageText: 'Filas por página',
      rangeSeparatorText: 'de',
      selectAllRowsItems: true,
      selectAllRowsItemText:'Todos'
  };

  const customStyles = {
      rows:{
          style: {
              fontSize:'.7rem',
              cursor:'pointer',
              '&:hover': {
                  background: 'rgba(114, 107, 121, 0.2)'
              }
          }
      }
  }

	const submitSetup = { voucher , dispatch, data: {active : !voucher.status} }
	const aux = {
		show: modal.modalShow,
		onHide:() => setModal({
		...modal,
		modalShow: false,
		}),
		fnToInvoke: () => submitFunction(submitSetup),
		modalSize : 'md',
		backdrop : 'static',
				modalText: 'Para confirmar el envío de información para activar/desactivar el Voucher OT, por favor presione en -Enviar-'
	}

	const auxEdit = {
		show: editModal.modalShow,
        voucherInfo: voucher,
		onHide:() => setEditModal({
		...modal,
		modalShow: false,
		}),
		//fnToInvoke: () => submitFunction(editSubmitSetup),
		modalSize : 'md',
		backdrop : 'static',
				modalText: 'Para confirmar el envío de información para ACTUALIZAR el Voucher OT, por favor presione en -Enviar-'
	}

	const auxPDF = {
		show: PDFModal.modalShow,
		onHide:() => setPDFModal({
            ...PDFModal,
            modalShow: false,
		}),
        PDFinfo: { ...voucher },
        PDFPreview: OTVoucher,
		modalSize : 'lg',
		backdrop : 'static',
        modalText: 'Para confirmar la descarga del Voucher OT, por favor presione en -Descargar PDF-'
	}

useEffect(() => {
    if(isCS(role)) {
      dispatch(otGetToAssignInfo('ot-to-technical'))
    }
  },[dispatch,role])
  return (
      <>
        <DataTable
            columns={colsVouchers} 
            data={filteredItems} 
            pagination={(filteredItems && filteredItems.length < 10) ? false : true}
            paginationComponentOptions={pagOpts}
            customStyles={customStyles}
            noDataComponent='No hay registros'
            progressPending={loading}
            progressComponent={<Loader />}
        />
        <ConfirmationModal
            {...aux}
        />
        <DownloadPDFModal 
            {...auxPDF}
        />
        <EditVoucherModal 
            {...auxEdit}
        />
      </>
  )
}

export default OTVoucherDatatable
