export const voucherState = {
	rma_id:'',
	date_from: '',
	date_to: '',
	description:'',
	client_id:'',
	active:false,
}

export const errorState = {
	status:false,
	message:''
}

export const modalState = {
  modalShow: false,
}
