import React from 'react';
import Axios from 'axios';

//contexto proveedor de store redux 
import { Provider } from 'react-redux';

//Router Principal
import AppRouter from './routers/AppRouter';
import store from './redux';


import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

//URL de servidor proveedor de la api (Laravel)
Axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//(local) Puerto = 8000 
//(production) server c-team rama feature/api-passport = 'https://dev.cteamglobal.com/';
//(testing) no hay aún

//header "común", cuando se obtiene el token del api
Axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

export const App = () => {
    return (
        <Provider store={ store } >
            <DndProvider backend={HTML5Backend}>
                <AppRouter />
            </DndProvider>
        </Provider>
    )
}
