import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { sendProfileInfo } from '../actions/user/user-profile';

export const Perfil = () => {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [info, setInfo] = useState({
        name:'',
        lastname:'',
        email:'',
        phone:'',
        cellphone:'',
        extension:'',
        password:'',
        password_confirmation:'',
        companies:[],
        username:'',
        role:'',
        current:''
    });

    const [error, setError] = useState({
        status:false,
        message:''
    });

    const handleChange = e => {
        setInfo({
            ...info,
            [e.target.name] : e.target.value
        });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const aux = Object.entries(info).filter( value => !(value[0] === 'companies') && value[1] );

        if(info.password && info.password.length < 6) {
            return setError({
                status:true,
                message:'La contraseña debe tener más de 6 caracteres'
            });
        }

        if ( info.password 
            && ((info.password && !info.password_confirmation) 
            && info.password !== info.password_confirmation
            && info.password_confirmation !== info.password) ) {
            return setError({
                status:true,
                message:'Ambas contraseñas deben ser idénticas'
            });
        }

        if( info.password && info.password_confirmation && !info.current) {
            return setError({
                status:true,
                message:'Debe introducir su contraseña actual'
            });
        }

        const fd = new FormData();
        aux.map(value => fd.append(`${value[0]}`, value[1]));

        await dispatch(sendProfileInfo(fd));

        if(info.password) {
            setInfo( info => {
                return setInfo({
                    ...info,
                    password:'',
                    password_confirmation:'',
                    current:''
                });
            });
        }
        
    }

    useEffect(() => {
        if(user && user.companies && user.role){
            setInfo({
                username:user.username,
                companies:[...user.companies],
                role:user.role.name,
                name: user.name,
                lastname: user.lastname,
                email: user.email,
                phone: user.phone,
                cellphone: user.cellphone,
                extension: user.extension,
                password:'',
                password_confirmation:'',
                current:''
            })

        }
        
    }, [user]);

    return (
        <form className='profile-form'>
            
        { 
                    error.status 
                    && <p style={{
                        color:'red', 
                        display:'flex', 
                        width:'80%', 
                        margin:'0 auto',
                        justifyContent:'center',
                        padding:'1rem',
                        background:'rgba(255, 0, 0, 0.3)',
                        border:'1px solid red',
                        borderRadius:'.3rem'
                    }}>
                        { error.message }
                    </p> 
                }

            <div className='profile-div'>
                <div className='profile-div-part-a'>
                    <div className='profile-full-name'>
                        <div className='profile-name'>
                            <label className="" htmlFor="profile-name">Nombre</label>
                            <input 
                                type='text' 
                                id='profile-name' 
                                placeholder='Nombre del usuario' 
                                value={ info.name }
                                name='name'
                                onChange={handleChange}
                            />
                        </div>
                        <div className='profile-lastname'>
                            <label className="" htmlFor="profile-lastname">Apellido</label>
                            <input 
                            type='text' 
                            id='profile-lastname' 
                            placeholder='Apellido del usuario' 
                            value={ info.lastname }
                            name='lastname'
                            onChange={handleChange}
                        />
                        </div>
                    </div>
                    <div className='profile-email'>
                        <label className="" htmlFor="profile-email">E-mail</label>
                        <input 
                            type='email' 
                            id='profile-email' 
                            placeholder='Correo electrónico' 
                            value={ info.email }
                            name='email'
                            onChange={handleChange}
                        />
                    </div>
                    <hr />
                    <div className='profile-telephone'>
                        <label className="" htmlFor="profile-telephone">Teléfono</label>
                        <input 
                            type='text' 
                            id='profile-telephone' 
                            placeholder='Ej: 15 1111 1111' 
                            value={ info.phone }
                            name='phone'
                            onChange={handleChange}
                        />
                    </div>
                    <div className='profile-cellphone'>
                        <label className="" htmlFor="profile-cellphone">Celular</label>
                        <input 
                            type='text' 
                            id='profile-cellphone' 
                            placeholder='Celular del usuario' 
                            value={ info.cellphone }
                            name='cellphone'
                            onChange={handleChange}
                        />
                    </div>
                    <div className='profile-interno'>
                        <label className="" htmlFor="profile-interno">Interno</label>
                        <input 
                            type='text' 
                            id='profile-interno' 
                            placeholder='Ej: 100' 
                            value={ info.extension ? info.extension : '' }
                            name='extension'
                            onChange={handleChange}
                        />
                    </div>

                </div>
                <div className='profile-div-part-b'>

                    <div className='profile-user'>
                        <label className="" htmlFor="profile-user">Usuario</label>
                        <input 
                            readOnly
                            id='profile-user' 
                            placeholder='Usuario' 
                            value={ info.username }
                        />
                    </div>
                    <div className='profile-company'>
                        <label className="" htmlFor="profile-company">Empresa</label>
                        <input 
                            readOnly
                            id='profile-company' 
                            placeholder='Empresa' 
                            value={ (info.companies) && info.companies.map( company => ` ${company.name}`) }
                        />
                    </div>
                    <div className='profile-role'>
                        <label className="" htmlFor="profile-role">Rol</label>
                        <input 
                            readOnly
                            id='profile-role' 
                            placeholder='Rol del usuario' 
                            value={info.role}
                        />
                    </div>
                    <hr />
                    <div className='profile-password'>
                        <label className="" htmlFor="profile-password">Cambiar contraseña</label>
                        <input 
                            type='password' 
                            id='profile-password' 
                            placeholder='Nueva contraseña' 
                            value={ info.password }
                            name='password'
                            onChange={handleChange}
                        />
                    </div>
                    <div className='profile-confirm-password'>
                        <label className="" htmlFor="profile-confirm-password">Confirmar contraseña</label>
                        <input 
                            type='password' 
                            id='profile-confirm-password' 
                            placeholder='Confirmar contraseña' 
                            value={ info.password_confirmation }
                            name='password_confirmation'
                            onChange={handleChange}
                        />
                    </div>
                    <div className='profile-password'>
                        <label className="" htmlFor="profile-password">Contraseña actual</label>
                        <input 
                            type='password' 
                            id='profile-current-password' 
                            placeholder='Contraseña actual' 
                            value={ info.current }
                            name='current'
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className='profile-buttons'>
                <button 
                    className='profile-button'
                    onClick={handleSubmit}
                >
                    <i className="fas fa-user"></i> Modificar usuario
                </button>
                <Link to='/panel' className='profile-return'><i className="fas fa-undo-alt"></i> Volver</Link>
            </div>
        </form>
    )
}
