import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

//Tippy para react (Tooltips)
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

//Redux actions
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../../actions/auth';

import { PATHS } from '../../types/paths';

import { getTickets } from '../../actions/tickets';

import { setProfileInfo } from '../../actions/user/user-profile';
import { cleanTheStorage } from '../../helpers/clean_the_storage';
import { otGetInfo } from '../../actions/ot/ot-get';
import { isCS, isPDV, isWatchmaker } from '../../helpers/permission';
import { otGetVoucherInfo } from '../../actions/ot/ot-voucher';

export const Nav = () => {
    const {name:role} = useSelector(state => state.auth.role)
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(cleanTheStorage());
        switch (location.pathname) {
            /**PERFIL DE USUARIO */
            case PATHS.panelConfigProfile:
                dispatch(setProfileInfo());
                break;
                
            /* OT */
            case PATHS.otGeneralTickets:
                if(isCS(role) || isWatchmaker(role)) {
                    dispatch(otGetInfo('general'));
                } else if(isPDV(role)) {
                    dispatch(otGetInfo('general-pdv'));
                }
                break;
                
            case PATHS.otToDeliverTickets:
                if(isCS(role)) {
                    dispatch(otGetInfo('ready-client-deliver-in-cs'));
                } else if(isPDV(role)) {
                    dispatch(otGetInfo('ready-client-deliver-in-pdv'));
                }
                break;
                
            case PATHS.otToConfirmTickets:
                dispatch(otGetInfo('budget-ready'));
                break;
                
            case PATHS.otDistributionTickets:
                if(isCS(role)) {
                    dispatch(otGetInfo('distribution'));
                } else if(isPDV(role)) {
                    dispatch(otGetInfo('to-cs'));
                }
                break;
                
            case PATHS.otRepairedTickets:
                dispatch(otGetInfo('repaired'));
                break;
                
            case PATHS.otPendantTickets:
                dispatch(otGetInfo('pendant'));
                break;
                
            case PATHS.otProductionTickets:
                dispatch(otGetInfo('in-prod'));
                break;
                
            case PATHS.otTransitTickets:
                dispatch(otGetInfo('in-transit'));
                break;

            case PATHS.otVouchers:
                dispatch(otGetVoucherInfo());
                break;

            default:
                break;
        }
    }, [location, dispatch, role])    
    
    const handleLogout = async() => {
        await dispatch( startLogout() );
        return document.location.href = '/panel'
    }
    const relocate = `${process.env.REACT_APP_OT_TO_CLIENT_URL}/panel?token=${localStorage.getItem('token')}`
    return (
        <nav className='navbar'>
            <div className='navbar-menu'>

                {/** BOTÓN PARA IR INICIO TICKETS*/}
                <Tippy content='Ir a inicio' placement='right'>
                    <a href={PATHS.clientGeneralTickets}>
                        <img src='/cteam/Cteam logo.png' alt='Logo C-team'  />
                    </a>
                </Tippy>

                {/**TICKETS*/}
                <Tippy content='Ir a tickets' placement='right'>
                    <a 
                        href={relocate} 
                        className='navbar-ticket-button' 
                    >
                        <i className="fas fa-ticket-alt"></i>
                    </a>
                </Tippy>         
                
                {/**OT*/}
                <Tippy content="Ir a OT's" placement='right'>
                    <Link 
                        to={PATHS.otGeneralTickets} 
                        className='navbar-ticket-button' 
                    >
                        <i className="fas fa-clock"></i>
                    </Link>
                </Tippy>           
                {/**Sala de edición*/}
                {/* <Tippy content="Sala de edición" placement='right'>
                    <Link 
                        to={PATHS.editRoom} 
                        className='navbar-ticket-button' 
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Link>
                </Tippy>  */}
            </div>

            <div className='navbar-user'>       

                {/** BOTÓN PARA CONFIGURACIÓN DE USUARIO */}
                <Tippy content='Ir al perfil de usuario' placement='right'>
                    <Link 
                        to={PATHS.panelConfigProfile} 
                        className='navbar-ticket-button' 
                    >
                        <i className="fas fa-user"></i>
                    </Link>
                </Tippy>

                {/** BOTÓN PARA CERRAR SESIÓN */}
                <Tippy content='Cerrar sesión actual' placement='right'>
                    <button 
                        className='navbar-logout-button' 
                        onClick={ handleLogout } 
                    >
                        <i className="fas fa-power-off"></i>
                    </button>
                </Tippy>
            </div>
        </nav>
    )
}
