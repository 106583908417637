import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getClientOTTicketInfo } from '../../../actions/ot/ot-create'
import { OTProductInfoInputsA } from './OTCreateInputs/OTProductInfoInputsA'
import { OTProductInfoInputsB } from './OTCreateInputs/OTProductInfoInputsB'
import { OTProductInfoInputsC } from './OTCreateInputs/OTProductInfoInputsC'
import { OTUserInfoInputs } from './OTCreateInputs/OTUserInfoInputs'

export const OTCreateTicket = () => {

  const authUserName = useSelector(state => state.auth.user.name)

  const initialState = {
    //User info
    dni:'',name:'',last_name:'',telephone:'',mail:'',mail2:'', has_warranty:false, warranty:'',

    //Product info
    type:'reloj', brand:'', model:'', plan:'',diagnostic:'',observations:'',attender:'',

    //Product details
    //Back
    ub1:false, up_belt_1:'', wc1:false, watch_center_1:'', db1:false, down_belt_1:'',
    //Front
    ub2:false, up_belt_2:'', wc2:false, watch_center_2:'', db2:false, down_belt_2:'',

    //Final info:
    repair_date:'', place: authUserName, total_price:'',ot_slug:'', file:''
  };
  
  const [userSearch, setUserSearch] = useState({status:false, message:''});
  const [otForm, setOtForm] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientOTTicketInfo());
  }, [dispatch])

  return (
    <div className='panel_cliente_marco_interno'>
      <div className='panel_ot_crear_ticket'>
        <div className='panel_ot_crear_ticket_titulo'>
          <p>Crear Nueva OT</p>
        </div>
        <form 
          className='panel_ot_crear_ticket_formularios'
          encType="multipart/form-data"
        >
          <div className='panel_ot_crear_1'>
            <h5>Ingrese correctamente los datos para crear una nueva OT</h5>
            <br />
            <p>
              Datos del cliente { 
                userSearch.status 
                && <small style={{color:'var(--orangecolor2)',marginLeft: '2.5rem'}}>{userSearch.message}</small>
              }
            </p>
            <OTUserInfoInputs 
              otForm={ otForm }
              setOtForm={ setOtForm }
              userSearch={ userSearch }
              setUserSearch={ setUserSearch }
              initialState={ initialState }
            />
          </div>
          <div className='panel_ot_crear_2'>
            <p>Datos del producto</p>
            <OTProductInfoInputsA 
              otForm={ otForm }
              setOtForm={ setOtForm }
            />
          </div>
          <div className='panel_ot_crear_3'>
            <p>Detalles del producto</p>
            <OTProductInfoInputsB 
              otForm={ otForm }
              setOtForm={ setOtForm }
            />
          </div>
          <div className='panel_ot_crear_4'>
            <p>Datos finales de OT</p>
            <OTProductInfoInputsC 
              otForm={ otForm }
              setOtForm={ setOtForm }
              initialState={ initialState }
            />
          </div>
        </form>
      </div>
    </div>
  )
}
