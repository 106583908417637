import makeRequest from "../../../../actions/makeRequest"
import { otGetVoucherInfo } from "../../../../actions/ot/ot-voucher"
import { postRequest } from "./requests"
import { errorState, updateVoucherState } from "./states"

export const handleChange = (stateFn, e) => {
	const {name,value} = e.target
	return stateFn(v => ({
		...v,
		[name]:value
	}))
}

export const submitValidation = ({ code, rma_id, client_id}) => {

	if( !code ) return {status:true, message:'Es necesario el código único de voucher'}
	if( !rma_id || !Number(rma_id) ) return {status:true, message:'Es necesaria una OT válida, y que esté asignada al voucher'}
	if( !client_id || !Number(client_id) ) return {status:true, message:'Es necesario un ID del cliente válido, y correspondiente con el OT'}

  return false
}

export const submitFunction = ({voucher, setVoucher, setError, dispatch}) => {

	postRequest.data = {
		...voucher,
	}
	
	setError(errorState)
	return new Promise(async(resolve) => {
		const aux = await dispatch(makeRequest(postRequest))
		setVoucher(updateVoucherState)
		dispatch(otGetVoucherInfo());
		resolve(aux)
	})
}
