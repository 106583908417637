import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { isCS, isPDV, isWatchmaker } from '../../../helpers/permission';
import { PATHS } from '../../../types/paths'

export const OTNavbar = () => {
  const {ticket_count} = useSelector(state => state.header);
  const {name:role} = useSelector(state => state.auth.role);

  return (
    
    <ul 
        id='ot_navbar'
        className={isWatchmaker(role) ? 'bg-light' : ''}
    >

    {
        isCS(role) &&
        <>
            <NavLink
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otGeneralTickets}
            >
                OT({ticket_count && ticket_count.general})
            </NavLink>

            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otToDeliverTickets}
            >
                A entregar({ticket_count && ticket_count.pendant})
            </NavLink>

            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otToConfirmTickets}
            >
                A confirmar({ticket_count && ticket_count.solved})
            </NavLink>

            
            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otDistributionTickets}
            >
                Distribución({ticket_count && ticket_count.closed})
            </NavLink>

            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otRepairedTickets}
            >
                Reparados({ticket_count && ticket_count.closed})
            </NavLink>

            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otPendantTickets}
            >
                Pendientes({ticket_count && ticket_count.closed})
            </NavLink>

            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otProductionTickets}
            >
                En producción({ticket_count && ticket_count.closed})
            </NavLink>

            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otTransitTickets}
            >
                En tránsito({ticket_count && ticket_count.closed})
            </NavLink>
    </>
    }

    {
        isWatchmaker(role) &&
        <NavLink
            activeClassName='panel_cliente_tipo_activo'
            to={PATHS.otGeneralTickets}
        >
            OT(-Relojero-)
        </NavLink>
    }
    {
        isPDV(role) &&
        <>
            <NavLink
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otGeneralTickets}
            >
                OT(-PDV-)
            </NavLink>
            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otDistributionTickets}
            >
                Distribución
            </NavLink>
            <NavLink 
                activeClassName='panel_cliente_tipo_activo'
                to={PATHS.otToDeliverTickets}
            >
                Para retirar
            </NavLink>
        </>
    }

    {
        (isCS(role) || isPDV(role)) && 

        <NavLink
            activeClassName='panel_cliente_tipo_activo'
            to={PATHS.otVouchers}
        >
            Vouchers
        </NavLink>
    }

  </ul>
  )
}
