import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getClientOTTicketInfo, getOTEditInfo } from '../../../actions/ot/ot-create'
import { OTProductInfoInputsA } from './OTCreateInputs/OTProductInfoInputsA'
import { OTProductInfoInputsB } from './OTCreateInputs/OTProductInfoInputsB'
import { OTProductInfoInputsC } from './OTCreateInputs/OTProductInfoInputsC'
import { OTUserInfoInputs } from './OTCreateInputs/OTUserInfoInputs'

export const OTEdit = () => {
  const { slug } = useParams();
  const {status, rma, observations} = useSelector(state => state.client.ticket_info)

  const initialState = {
    //User info
    dni:'',name:'',last_name:'',telephone:'',mail:'',mail2:'', has_warranty:false, warranty:'',

    //Product info
    type:'reloj', brand:'', model:'', plan:'',diagnostic:'',observations:'',attender:'',

    //Product details
    //Back
    ub1:false, up_belt_1:'', wc1:false, watch_center_1:'', db1:false, down_belt_1:'',
    //Front
    ub2:false, up_belt_2:'', wc2:false, watch_center_2:'', db2:false, down_belt_2:'',

    //Final info:
    repair_date:'', place:'', total_price:'',ot_slug:'', file:''
  };
  
  const [userSearch, setUserSearch] = useState({status:false, message:''});
  const [otForm, setOtForm] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    /* dispatch(getClientOTTicketInfo()); */
    dispatch(getOTEditInfo(slug))
  }, [dispatch,slug]) 

  useEffect(() => {
    if(status === 'success') {
      setOtForm({
        dni:rma?.client.dni, name: rma?.client.name, last_name: rma?.client.lastname || '', telephone: rma?.client.phone,
        mail: rma?.client.email, mail2: rma?.client.optional_email || '', has_warranty: rma?.warranty, warranty: (rma &&rma?.due_date) ? rma.due_date : '',
        type: rma?.swatch_object.brand.type, brand: rma?.swatch_object.brand.name, model: rma?.swatch_object.model,
        plan: rma?.warranty_state?.name,diagnostic: rma?.pre_diagnostic, observations: rma?.observation, attender:rma?.vendor,
        ub1:Boolean(observations.back_superior), up_belt_1:observations?.back_superior || '',
        wc1:Boolean(observations.back_center), watch_center_1:observations?.back_center || '',
        db1:Boolean(observations.back_bottom), down_belt_1:observations?.back_bottom || '',
        ub2:Boolean(observations.front_superior), up_belt_2:observations?.front_superior || '',
        wc2:Boolean(observations.front_center), watch_center_2:observations?.front_center || '',
        db2:Boolean(observations.front_bottom), down_belt_2:observations?.front_bottom || '',
        repair_date:rma?.fix_date, place: rma?.ticket.to_deliver_to_customer[0].username, total_price:rma.total_amount < 1 ? '' : rma.total_amount, ot_slug: rma?.slug
      })
    }
  },[status, rma, observations])
  return (
    <div className='panel_cliente_marco_interno'>
      <div className='panel_ot_crear_ticket'>
        <div className='panel_ot_crear_ticket_titulo'>
          <p>Edición de OT #{slug}</p>
        </div>
        <form 
          className='panel_ot_crear_ticket_formularios'
          encType="multipart/form-data"
        >
          <div className='panel_ot_crear_1'>
            <h5>Ingrese correctamente los datos para modificar la OT #{slug}</h5>
            <br />
            <p>
              Datos del cliente { 
                userSearch.status 
                && <small style={{color:'var(--orangecolor2)',marginLeft: '2.5rem'}}>{userSearch.message}</small>
              }
            </p>
            <OTUserInfoInputs 
              otForm={ otForm }
              setOtForm={ setOtForm }
              userSearch={ userSearch }
              setUserSearch={ setUserSearch }
              initialState={ initialState }
            />
          </div>
          <div className='panel_ot_crear_2'>
            <p>Datos del producto</p>
            <OTProductInfoInputsA 
              otForm={ otForm }
              setOtForm={ setOtForm }
            />
          </div>
          <div className='panel_ot_crear_3'>
            <p>Detalles del producto</p>
            <OTProductInfoInputsB 
              otForm={ otForm }
              setOtForm={ setOtForm }
            />
          </div>
          <div className='panel_ot_crear_4'>
            <p>Datos finales de OT</p>
            <OTProductInfoInputsC 
              otForm={ otForm }
              setOtForm={ setOtForm }
              initialState={ initialState }
            />
          </div>
        </form>
      </div>
    </div>
  )
}
